import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import * as AppContext from '../../../src/AppContext';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;

export default function PrivacyPage() {
  const app = useContext(AppContext.Context);
  const [privacyImage, setPrivacyImage] = useState('');

  useEffect(() => {
    async function fetchData() {
      app.actions.setLoading(true);
      let params = {
        $and: [
          {
            label: '隱私政策',
          },
        ],
      };
      const result = await app.actions.getBlogs({query: params});
      const [
        {
          content: [, {src}],
        },
      ] = result;
      setPrivacyImage(src);
      app.actions.setLoading(false);
    }

    fetchData();
  }, [app.actions]);
  return (
    <Wrapper>
      <div className="constraint">
        <h1>隱私政策</h1>
        <img src={privacyImage} alt="" />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;
  padding: 40px 0;
  & .constraint {
    max-width: ${MAX_WIDTH - 200}px;
    margin: 0 auto;
    padding: 0px 20px;
    h1 {
      text-align: center;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 0px;
      }
    }
    img {
      max-width: 100%;
      width: 100%;
    }
  }
`;
